<template>
    <div class="flex flex-col-reverse lg:flex-row relative">
        <div class="lg:w-3/4">
            <p>
                If you have a question or need technical support, there are
                several ways to get help:
            </p>
            <p>You can call +1 480-618-5121</p>
            <ul>
                <li>Preview days: 9/23 – 9/25</li>
            </ul>
            <ul>
                <li style="list-style-type: none">
                    <ul>
                        <li>9/22: 12 pm – 2 pm Pacific</li>
                        <li>9/23: 12 pm – 2 pm Pacific</li>
                        <li>9/24: 12 pm – 2 pm Pacific</li>
                        <li>9/25: 12 pm – 4 pm Pacific</li>
                    </ul>
                </li>
                <li>
                    Conference Days 9/28 – 10/2/2020
                    <ul>
                        <li>Each day: 7 am – 3:30 pm Pacific</li>
                    </ul>
                </li>
            </ul>
            <p>
                If you are in the meeting and have questions or are experiencing
                problems, you can chat with a support person. Simply click on
                the chat icon in the bottom right corner of your screen.
            </p>
            <p>
                You can access our virtual Help Desk. Here you can speak with a
                staff member who can help you with your tech setup, answer
                questions about the meeting, and more. To visit the Help Desk,
                <router-link to="/schedule">check the schedule!</router-link>
            </p>
            <p>
                <a
                    href="https://us02web.zoom.us/rec/share/juvwwnQBQqMbWxpK0wUqbVJ85dij9IV0ktc52eJcTZmv_6xUdn25CWHMvkXSeL2V.LZn42vWNYL98xyUe"
                    target="_blank"
                    rel="noopener noreferrer"
                    >View the training tutorial!</a
                >
                (Passcode: n9s*9*sk)
            </p>
            <h2 id="login">Logging in to the 2020 Strategic Conference</h2>
            <p>
                Since you are accessing these FAQs from within the conference
                platform, we assume you were able to log in successfully.
            </p>
            <ul>
                <li>
                    Can someone else log in for me?
                    <ul>
                        <li>
                            No, your login information is specific to you and
                            should be kept private. For security purposes, you
                            can only be logged in from 1 location at a time.
                        </li>
                    </ul>
                </li>
                <li>
                    Can I access the platform before the event begins to confirm
                    my equipment will work?
                    <ul>
                        <li>
                            Yes, attendees will be given access to the virtual
                            event platform beginning September 22. An Help Desk
                            meeting room will be available from 10 am – 12 pm
                            Pacific. Visit the
                            <router-link to="/schedule"
                                >“MY SCHEDULE”</router-link
                            >
                            tab to access.
                        </li>
                    </ul>
                </li>
            </ul>
            <p>
                Presenters: In the Help Desk, you can test your camera and
                microphone and explore the features and controls. Please note
                that the room is open to ALL attendees during this time. It is
                not a private room.
            </p>
            <h2 id="sessions"><b>Education Sessions</b></h2>
            <p>
                For the plenary sessions, whiteboard sessions, forums, etc.
                <router-link to="/sessions">visit the Education tab</router-link
                >.
            </p>
            <h2 id="meetings"><b>Scheduled Meetings</b></h2>
            <ul>
                <li>
                    In what time zone are the meetings scheduled?
                    <ul>
                        <li>
                            All times displayed on the CGA site take the time
                            zone settings from your browser. The meetings are
                            technically scheduled based on the Pacific time
                            zone.
                        </li>
                    </ul>
                </li>
                <li>
                    What if I become disconnected?
                    <ul>
                        <li>
                            Return to the “MY SCHEDULE” or “EDUCATION” page and
                            click on the meeting or session link again. If you
                            are having problems joining, please click on the
                            “Live Chat” icon in the bottom right hand corner of
                            your screen.
                        </li>
                    </ul>
                </li>
            </ul>
            <h2 id="participants">
                <b>Communicating With Other Participants</b>
            </h2>
            <ul>
                <li>
                    Where can I see who is attending?
                    <ul>
                        <li>
                            Click on the
                            <router-link to="/attendees"
                                >“ATTENDEES”</router-link
                            >
                            tab on the top menu bar. You will see a random
                            selection of participants and can search for other
                            participants by first name, last name, or company.
                        </li>
                    </ul>
                </li>
                <li>
                    Are the private meetings being recorded?
                    <ul>
                        <li>
                            No, neither the private meetings, nor the chat
                            messages are recorded.
                        </li>
                    </ul>
                </li>
                <li>
                    Can I get a transcript of the chat in the private meeting
                    rooms? No, the chat messages are not recorded.
                </li>
                <li>
                    Can I contact or message other attendees?
                    <ul>
                        <li>
                            Yes, you can search for an attendee by first name,
                            last name, or company under the
                            <router-link to="/attendees"
                                >“ATTENDEES”</router-link
                            >
                            tab on the top menu bar. Then click on the “Send a
                            Message” button.
                        </li>
                    </ul>
                </li>
            </ul>
            <h2 id="troubleshooting"><b>Troubleshooting</b></h2>
            <ul>
                <li>
                    How do I get help if I am having technical issues?
                    <ul>
                        <li>
                            There are several ways to get help if you are having
                            issues with the site.
                        </li>
                        <li>
                            Click on the “HELP” tab on the top menu bar (this
                            page).
                        </li>
                        <li>
                            Click on the help icon on the bottom right corner or
                            your screen to chat live.
                        </li>
                        <li>Call +1 480-618-5121.</li>
                        <li>
                            Email us:
                            <a href="mailto:conference@cagrocers.com"
                                >conference@cagrocers.com</a
                            >
                        </li>
                        <li>
                            Live technical support is available the days of the
                            conference from 7 a.m. to 3:30 p.m. Pacific time.
                        </li>
                    </ul>
                </li>
            </ul>
            <h2 id="what-should-i-do"><b>What should I do if…</b></h2>
            <ul>
                <li>
                    My scheduled meeting is not showing up on my schedule?
                    Contact CGA at
                    <a href="mailto:conference@cagrocers.com"
                        >conference@cagrocers.com</a
                    >
                </li>
                <li>
                    I cannot access a private meeting? Please click on the help
                    icon on the bottom right of your screen to chat live.
                </li>
                <li>
                    I can’t hear/see other attendees in a private meeting or
                    they can’t hear/see me? Please click on the help icon on the
                    bottom right of your screen to chat live.
                </li>
                <li>
                    My audio/video is buffering.
                    <ul>
                        <li>Check the speed on your internet connection</li>
                        <li>
                            Try turning off your camera. A camera can consume a
                            lot of bandwidth
                        </li>
                        <li>
                            Close all other applications running on your device.
                        </li>
                        <li>
                            Make sure that your device is as close to the router
                            as possible. If possible, use a hard-wired
                            connection
                        </li>
                        <li>
                            Click on the help icon on the bottom right of your
                            screen to chat live.
                        </li>
                    </ul>
                </li>
                <li>
                    I hear an echo when I talk or from other attendees.
                    <ul>
                        <li>Try muting and then unmuting.</li>
                        <li>
                            If possible, do not use the built-in microphone and
                            speakers on your laptop. Use an external headset or
                            speaker.
                        </li>
                    </ul>
                </li>
            </ul>
            <h2 id="practices">
                <b>Best Practices For Meeting Participation</b>
            </h2>
            <p>
                To ensure that your participation in CGA is as successful as
                possible, we recommend the following best practices:
            </p>
            <h3>Equipment</h3>
            <p><b>General requirements</b></p>
            <ul>
                <li style="list-style-type: none">
                    <ul>
                        <li>
                            You will need access to either a PC, Mac, tablet, or
                            smartphone. A PC or Mac is recommended. To
                            participate in the meetings, it is recommended that
                            you have an internal or external camera so that you
                            can be seen on video.
                        </li>
                        <li>Microphones</li>
                        <li>
                            Clear audio can make a big difference. Laptop/phone
                            audio can work, but audio through an external
                            microphone (connected by wire or Bluetooth) can be a
                            huge plus. We recommend:
                        </li>
                        <li>
                            <a
                                href="https://www.amazon.com/Logitech-Headset-H390-Noise-Cancelling/dp/B000UXZQ42/"
                                >Logitech USB Headset H390 with Noise Cancelling
                                Mic</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://www.amazon.com/Education-1EDU-AE36WH-ITE-Headphone-Microphone-Single/dp/B00L2JUJ68/"
                                >Avid Education 1EDU-AE36WH-ITE Headphone with
                                Boom Microphone, Single Plug, White</a
                            >
                        </li>
                        <li>Browser</li>
                        <li>
                            CGA will support the latest versions of Chrome,
                            Safari, Firefox, and Edge. IE11 is not fully
                            supported and you should use a different browser.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul>
                <li>
                    <i>Internet Connection Tips</i>
                    <ul>
                        <li>
                            Generally, wired connections are better than
                            wireless connections, and wireless connections are
                            better than cellular based connections.
                        </li>
                    </ul>
                </li>
                <li>
                    While CGA supports tablets and smartphones, we recommend
                    using a PC or Mac as some functions will not work on a
                    tablet or phone.
                </li>
            </ul>
            <h3>Your Surroundings</h3>
            <ul>
                <li>
                    Find a quiet area for participation. Avoid areas with loud
                    background noise (traffic, wind, loud vents, or appliances).
                </li>
                <li>
                    Be one with the light and find the balance! Try to avoid a
                    dark room. Natural room sunlight is good – just do not stand
                    with the light shining right behind you.
                </li>
                <li>
                    Consider the acoustics of the room. A small room with dead
                    acoustics will not flatter a voice.
                </li>
                <li>Position yourself relatively close to the camera</li>
                <li>
                    Frame the camera so that your eyes are in the top third of
                    the frame.
                </li>
                <li>Use a plain, uncluttered background.</li>
            </ul>
            <h3>Screen Sharing</h3>
            <ul>
                <li>
                    Use two monitors: one for the video chat; one for the
                    presentation
                </li>
                <li>
                    If sharing full-screen in PowerPoint, you sometimes have to
                    share out a separate window or you’ll see the Presenter’s
                    view
                </li>
                <li>
                    Click here for detailed instructions on how to share a
                    PowerPoint presentation in Windows
                </li>
                <li>Or have another team member run the presentation</li>
                <li>Practice ahead of your presentation</li>
            </ul>
            <h3>Meeting Participation</h3>
            <ul>
                <li>
                    We encourage participants to turn on their camera in the
                    private meeting rooms so that other participants can see who
                    is talking. But please note that only 12 people can be on
                    video at once in the private meeting rooms.
                </li>
                <li>
                    Please mute yourself when not talking to avoid background
                    interruptions (barking dogs, phones ringing, family members
                    talking etc.)
                </li>
                <li>Have a Meeting Plan</li>
                <li>
                    Since only 12 people at a time can be on video, make a plan
                    with your team to decide who will be on video, who will
                    speak when, who will share the presentation, etc.
                </li>
                <li>
                    Consider coordinating your meeting agenda with your trading
                    partner. You can contact them ahead of time via email or
                    phone (if you have their contact information) or you can use
                    the messaging feature in the site.
                </li>
            </ul>
            <h3>Prepare for the Unexpected</h3>
            <ul>
                <li>This is technology, some things *may* go wrong.</li>
                <li>
                    Have a back-up plan for if someone gets sick, if someone’s
                    internet connection drops, if a laptop crashes, if someone’s
                    audio goes haywire. Can someone quickly take over the
                    presentation?
                </li>
            </ul>
        </div>
        <div class="lg:w-1/4 lg:pl-8">
            <h2>Quick Links</h2>
            <ul>
                <li><a href="#login">Logging In</a></li>
                <li><a href="#sessions">Education Sessions</a></li>
                <li><a href="#meetings">Scheduled Meetings</a></li>
                <li>
                    <a href="#participants"
                        >Communicating With Other Participants</a
                    >
                </li>
                <li><a href="#troubleshooting">Troubleshooting</a></li>
                <li><a href="#what-should-i-do">What should I do if...</a></li>
                <li>
                    <a href="#practices"
                        >Best Practices For Meeting Participation</a
                    >
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "helpAbc"
};
</script>

<style lang="scss" scoped>
@import "../../styles/setup/colors";
@import "../../styles/views/help";
</style>
